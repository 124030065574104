<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="文章标题" prop="title">
        <el-col :xl="10">
          <el-input
            placeholder="请输入文章标题"
            v-model="ruleForm.title"
            clearable
          />
        </el-col>
      </el-form-item>
      <el-form-item label="文章来源" prop="originAuthor">
        <el-col :xl="10">
          <el-input
            placeholder="文章来源"
            v-model="ruleForm.originAuthor"
            clearable
          />
        </el-col>
      </el-form-item>
      <!-- <el-form-item label="文章分类" prop="dynamicId">
        <el-select v-model="ruleForm.dynamicId" placeholder="请选择">
          <el-option
            v-for="(item, index) in ['1']"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="文章封面" prop="photos">
        <el-upload
          :action="$store.state.uploadingUrl"
          list-type="picture-card"
          :class="{ disabled: uploadDisabled }"
          :limit="1"
          :on-progress="handProgress"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :file-list="fileList"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="文章内容" prop="details">
        <quill-editor
          style="height: 100%"
          v-model="ruleForm.details"
          ref="myQuillEditor"
          :options="editorOption"
        />
      </el-form-item>
      <el-form-item label="是否发布" prop="isDrafts">
        <el-switch
          v-model="ruleForm.isDrafts"
          active-text="发布"
          inactive-text="关闭"
        />
        <span class="ml10" style="vertical-align: middle;color: rgb(253, 99, 78);">关闭状态即保存到"草稿箱"</span>
      </el-form-item>
    </el-form>
  </div>
</template> 

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写文章内容'
export default {
  data() {
    var valiIcon = (rule, value, callback) => { // 图片验证
      if (!this.ruleForm.photos) {
        callback();
      } else {
        callback();
      }
    }
    return {
      editorOption: quillConfig,
      disabled: false,
      ruleForm: {
        title: "",
        originAuthor: "",
        dynamicId: "",
        details: "",
        isDrafts: false,
        photos: "",
      },
      fileList: [],
      rules: {
        title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        photos: [{ required: true,  }],
        isDrafts: [{ required: true, message: "请输入文章内容" }],
      },
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    uploadDisabled(){
      return this.ruleForm.photos !== "";
    },
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.photos = "";
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm.photos = `https://ma-oos.oss-cn-beijing.aliyuncs.com/${response.data}`;
    },
    submitForm(payload) {
      if(!this.ruleForm.photos){
        return this.$message.error('请上传文章封面')
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.isDrafts = data.isDrafts ? 0 : 1;
          // if(payload) data.isDrafts = 0 ;
          this.$emit("submitForm", data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
</style>